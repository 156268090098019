.cursor-none {
  cursor: text;
}
.flex-text-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  flex-direction: row;
}
.flex-text-booking-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  flex-direction: row;
}
.list-style-none {
  list-style: none !important;
}
.room-amenities-list-items {
  padding: 5px 0px !important;
  font-size: 14px !important;
  color: #444 !important;
  /* padding-left: 0px !important; */
}
.anchor-text{
  text-decoration: none;
  color: white;
  &:hover{
    color: white;
    cursor: pointer;
  }
}
@media screen and (max-width: 991px) {
  .flex-text-container {
    flex-direction: column;
  }
  .full-button-width {
    width: 100%;
  }
}
@media screen and (max-width: 573px) {
  .flex-text-checkout-container {
    align-items: center;
    gap: 5px;
    flex-direction: column;
  }
}
@media screen and (max-width: 700px) {
  .flex-text-booking-container {
    flex-direction: column;
  }
}
.wrap-break-word{
  word-wrap: break-word;
}

.anchor-btn{
  text-decoration: underline;
  padding-left: 15px;
  font-size: 15px;
  color: #444;
  cursor: pointer;
  &:hover{
    color: black;
  }
}

.text-break-all{
  word-break: break-all;
}

.line-height-30{
  line-height: 30px;
}
.cursor-pointer{
  cursor: pointer;
}
.dropdown-zIndex{
  z-index: 901 !important;
}

.border-0 {
  border:0px;
}

.outline-0 {
  outline:0px;
}

.p-relative {
  position: relative
}

.country-container .stdropdown-container {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border:0;

}

.mb-0.mb-0 {
  margin-bottom: 0px;
}

.country-container .stdropdown-container .stdropdown-menu {
  scrollbar-width: thin;
}

@media screen and (min-width: 992px) {
  .field-align-center {
    align-items: center;
  }
}
@media screen and (max-width: 991px){
  .field-align-baseline{
    align-items: baseline;
  }
}