.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

.w-form-formrecaptcha {
  margin-bottom: 8px;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

body {
  color: #414042;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.1;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
}

h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

p {
  margin-bottom: 0;
}

a {
  transition: all 0.2s;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.25rem;
}

img {
  max-width: 100%;
  display: inline-block;
}

label {
  margin-bottom: 0.25rem;
  font-weight: 500;
}

blockquote {
  border-left: 0.25rem solid #e2e2e2;
  margin-bottom: 0;
  padding: 0 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

figure {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

figcaption {
  text-align: center;
  margin-top: 0.25rem;
}

.utility-page_component {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
}

.utility-page_form-block {
  max-width: 20rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.utility-page_form {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.utility-page_image {
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}

.global-styles {
  display: block;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.margin-custom2 {
  margin: 2.5rem;
}

.padding-xlarge {
  padding: 4rem;
}

.margin-xlarge {
  margin: 4rem;
}

.margin-xsmall {
  margin: 0.5rem;
}

.padding-xhuge {
  padding: 8rem;
}

.margin-custom1 {
  margin: 1.5rem;
}

.padding-0 {
  padding: 0;
}

.padding-xxhuge {
  padding: 12rem;
}

.padding-huge {
  padding: 6rem;
}

.margin-large {
  margin: 3rem;
}

.padding-xxlarge {
  padding: 5rem;
}

.margin-xxsmall {
  margin: 0.25rem;
}

.padding-custom3 {
  padding: 3.5rem;
}

.padding-large {
  padding: 3rem;
}

.margin-tiny {
  margin: 0.125rem;
}

.padding-small {
  padding: 1rem;
}

.padding-custom2 {
  padding: 2.5rem;
}

.margin-custom3 {
  margin: 3.5rem;
}

.padding-custom1 {
  padding: 1.5rem;
}

.margin-huge {
  margin: 6rem;
}

.padding-medium {
  padding: 2rem;
}

.padding-xsmall {
  padding: 0.5rem;
}

.margin-xxlarge {
  margin: 5rem;
}

.padding-xxsmall {
  padding: 0.25rem;
}

.margin-xhuge {
  margin: 8rem;
}

.padding-tiny {
  padding: 0.125rem;
}

.margin-small {
  margin: 1rem;
}

.margin-medium {
  margin: 2rem;
}

.margin-xxhuge {
  margin: 12rem;
}

.margin-0 {
  margin: 0;
}

.margin-horizontal {
  margin-top: 0;
  margin-bottom: 0;
}

.padding-top {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.margin-vertical {
  margin-left: 0;
  margin-right: 0;
}

.margin-bottom {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.padding-left {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.padding-vertical {
  padding-left: 0;
  padding-right: 0;
}

.padding-horizontal {
  padding-top: 0;
  padding-bottom: 0;
}

.margin-right {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.margin-top {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.margin-left {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}

.padding-right {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.padding-bottom {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.form_checkbox {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-left: 0;
  display: flex;
}

.form_checkbox-icon {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.125rem;
  margin: 0 0.5rem 0 0;
}

.form_checkbox-icon.w--redirected-checked {
  width: 0.875rem;
  height: 0.875rem;
  background-size: 90%;
  border-radius: 0.125rem;
  margin: 0 0.5rem 0 0;
}

.form_checkbox-icon.w--redirected-focus {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.125rem;
  margin: 0 0.5rem 0 0;
  box-shadow: 0 0 0.25rem 0 #3898ec;
}

.fs-styleguide_background {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.fs-styleguide_spacing {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  background-image: linear-gradient(
    to top,
    rgba(0, 115, 230, 0.1),
    rgba(255, 255, 255, 0)
  );
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-content: start;
  justify-content: start;
  align-items: start;
  justify-items: stretch;
  display: grid;
  position: relative;
}

.form_checkbox-label {
  font-size: 0.875rem;
}

.icon-1x1-small {
  width: 1rem;
  height: 1rem;
  flex: none;
}

.overflow-auto {
  overflow: auto;
}

.spacing-clean {
  margin: 0;
  padding: 0;
}

.icon-1x1-large {
  width: 2.5rem;
  height: 2.5rem;
}

.z-index-2 {
  z-index: 2;
  position: relative;
}

.fs-styleguide_background-space {
  width: 1px;
  height: 1px;
  margin: 5rem;
}

.text-weight-semibold {
  font-weight: 600;
}

.text-style-strikethrough {
  text-decoration: line-through;
}

.fs-styleguide_item {
  grid-column-gap: 1.125rem;
  grid-row-gap: 1.125rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-content: start;
  justify-content: start;
  align-items: start;
  justify-items: start;
  padding-bottom: 3rem;
  display: grid;
  position: relative;
}

.fs-styleguide_item.is-stretch {
  justify-items: stretch;
}

.max-width-full {
  width: 100%;
  max-width: none;
}

.fs-styleguide_item-header {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;
}

.fs-styleguide_heading-large {
  font-size: 6rem;
}

.background-color-black {
  color: #f5f5f5;
  background-color: #000;
}

.z-index-1 {
  z-index: 1;
  position: relative;
}

.text-color-black {
  color: #000;
}

.text-color-grey {
  color: gray;
}

.fs-styleguide_2-col {
  width: 100%;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.fs-styleguide_2-col.is-align-start {
  align-items: start;
}

.form_message-success {
  padding: 1.25rem;
}

.fs-styleguide_row {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.heading-style-h3 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.text-rich-text h1,
.text-rich-text h2,
.text-rich-text h3,
.text-rich-text h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.text-rich-text h5,
.text-rich-text h6 {
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.text-rich-text p {
  margin-bottom: 1rem;
}

.text-rich-text ul,
.text-rich-text ol {
  margin-bottom: 1.5rem;
}

.text-rich-text a {
  color: #000;
}

.text-rich-text a:hover {
  color: #f25f3a;
}

.container-small {
  width: 100%;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}

.icon-height-small {
  height: 1rem;
}

.icon-1x1-medium {
  width: 2rem;
  height: 2rem;
}

.heading-style-h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.1;
}

.padding-global {
  padding-left: 2.3rem;
  padding-right: 2.3rem;
}

.padding-global.position-relative {
  z-index: 1;
  position: relative;
}

.text-weight-normal {
  font-weight: 400;
}

.text-weight-normal.text-size-xlarge {
  font-size: 3.5rem;
}

.padding-section-small {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.max-width-small {
  width: 100%;
  max-width: 20rem;
}

.text-color-white {
  color: #fff;
}

.text-style-italic {
  font-style: italic;
}

.text-weight-medium {
  font-weight: 500;
}

.overflow-hidden {
  overflow: hidden;
}

.fs-styleguide_section-header {
  width: 100%;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  border-bottom: 1px solid #000;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding-bottom: 3rem;
  line-height: 1.4;
  display: grid;
}

.text-size-tiny {
  font-size: 0.75rem;
}

.max-width-xxlarge {
  width: 100%;
  max-width: 80rem;
}

.fs-styleguide_1-col {
  width: 100%;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.overflow-visible {
  overflow: visible;
}

.fs-styleguide_empty-box {
  z-index: -1;
  height: 3rem;
  min-width: 3rem;
  background-color: rgba(0, 115, 230, 0.1);
  border: 1px dashed #0073e6;
  position: relative;
}

.text-weight-light {
  font-weight: 300;
}

.fs-styleguide_heading-medium {
  font-size: 4rem;
}

.max-width-xsmall {
  width: 100%;
  max-width: 16rem;
}

.fs-styleguide_4-col {
  width: 100%;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.text-size-regular {
  font-size: 1rem;
}

.text-weight-xbold {
  font-weight: 800;
}

.text-align-right {
  text-align: right;
}

.text-weight-bold {
  font-weight: 700;
}

.max-width-medium {
  width: 100%;
  max-width: 32rem;
}

.fs-styleguide_item-wrapper {
  width: 100%;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.max-width-large {
  width: 100%;
  max-width: 48rem;
}

.max-width-large.align-center {
  margin-left: auto;
  margin-right: auto;
}

.fs-styleguide_header-block {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: start;
  display: grid;
}

.background-color-white {
  background-color: #fff;
}

.text-style-muted {
  opacity: 0.6;
}

.text-size-small {
  font-size: 0.875rem;
}
/* farooq */
.text-size-small-list {
  font-size: 0.8rem;
  /*  */
  color: #f25f3a;
}
/*  */
.heading-style-h4 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

.max-width-xlarge {
  width: 100%;
  max-width: 64rem;
}

.form_radio-icon {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0.5rem;
}

.form_radio-icon.w--redirected-checked {
  width: 0.875rem;
  height: 0.875rem;
  border-width: 0.25rem;
}

.form_radio-icon.w--redirected-focus {
  width: 0.875rem;
  height: 0.875rem;
  box-shadow: 0 0 0.25rem 0 #3898ec;
}

.text-style-nowrap {
  white-space: nowrap;
}

.text-align-left {
  text-align: left;
}

.background-color-grey {
  background-color: #f2f2f2;
}

.form_input {
  min-height: 3rem;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0.75rem;
  padding: 0.5rem 1rem;
}

.form_input.is-text-area {
  min-height: 8rem;
  padding-top: 0.75rem;
}

.form_input.is-icon {
  background-image: url("../images/calendar-icon_1.svg");
  background-position: 96%;
  background-repeat: no-repeat;
  background-size: auto;
}

.heading-style-h6 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.padding-section-large {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.fs-styleguide_3-col {
  width: 100%;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: grid;
}

.fs-styleguide_3-col.is-align-start {
  align-items: start;
}

.text-style-link {
  color: #000;
  text-decoration: underline;
}

.text-style-link:hover {
  color: #f25f3a;
}

.text-size-large {
  font-size: 1.5rem;
}

.fs-styleguide_header {
  background-color: rgba(0, 0, 0, 0.05);
}

.heading-style-h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.fs-styleguide_label {
  color: #fff;
  background-color: #0073e6;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.75rem;
  font-weight: 600;
  line-height: 1.4;
  display: flex;
}

.fs-styleguide_label.is-tag {
  background-color: #be4aa5;
}

.fs-styleguide_version {
  z-index: 5;
  color: #000;
  font-weight: 500;
  text-decoration: none;
}

.heading-style-h5 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
}
/* farooq */

.heading-style-h5-a {
  text-decoration: none;
  color: black;
}

.heading-style-h5-a:hover {
  /* text-decoration: none; */
  color: #f25f3a;
  cursor: pointer;
}
.hotel-image {
  text-decoration: none;
}
.hotel-image:hover {
  cursor: pointer;
}

/* far */
.container-large {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.icon-height-medium {
  height: 2rem;
}

.text-style-allcaps {
  text-transform: uppercase;
}

.overflow-scroll {
  overflow: scroll;
}

.form_message-error {
  margin-top: 0.75rem;
  padding: 0.75rem;
}

.icon-height-large {
  height: 3rem;
}

.text-align-center {
  text-align: center;
}

.form_component {
  margin-bottom: 0;
}

.max-width-xxsmall {
  width: 100%;
  max-width: 12rem;
}

.layer {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.text-style-quote {
  border-left: 0.25rem solid #e2e2e2;
  margin-bottom: 0;
  padding: 0 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

/* custom button */

.custom-button {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px,
    rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: Cerebri Sans-Regular, -apple-system, system-ui, Roboto,
    sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.custom-button:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

/*  */

.button {
  color: #fff;
  text-align: center;
  background-color: #f25f3a;
  border-radius: 0.5em;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
}

.button:hover {
  background-color: #ff4c1f;
}

.button.is-text {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid rgba(0, 0, 0, 0);
}

.button.is-small {
  padding: 0.5rem 1.25rem;
}

.button.is-large {
  padding: 1rem 2rem;
}

.button.is-secondary {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #000;
  border-bottom-width: 0.125rem;
}

.button.is-secondary.is-alternate {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-icon {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.button.is-link {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  padding: 0.25rem 0;
  line-height: 1;
  text-decoration: none;
}

.button.is-link.is-icon {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.button.is-link.is-icon.text-color-orange1 {
  color: #f25f3a;
}

.button.is-link.is-icon.text-color-orange1:hover {
  cursor: pointer;
}

.button.is-nav-top-right {
  padding: 0.5rem 1rem;
  font-size: 0.785rem;
  font-weight: 500;
}

.button.is-nav-top-right.is-single {
  color: #f25f3a;
  background-color: #fff;
}

.button.is-third {
  background-color: #414042;
}

.button.no-corner {
  border-radius: 0;
}

.fs-styleguide_classes {
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.text-size-medium {
  font-size: 1.25rem;
}

.text-size-medium.text-color-white {
  color: #fff;
}

.fs-styleguide_section {
  grid-column-gap: 6rem;
  grid-row-gap: 6rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: start;
  display: grid;
}

.fs-styleguide_section.is-vertical {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-columns: 1fr;
}

.button-group {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  /* farooq */
  text-align: center;
  margin-left: 0px;
  /* display: flex; */
  /*  */
}

.singlebooking-button-group-list {
  flex-flow: wrap;
  border-left: 1px solid #c1c1c1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 20%;
  flex-grow: 1;
  overflow-y: auto;
  white-space: wrap;
  text-overflow: ellipsis;
}
.resultpage-button-group-list {
  flex-flow: wrap;
  border-left: 1px solid #c1c1c1;
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  flex-grow: 1;
  overflow-y: auto;
  white-space: wrap;
  text-overflow: ellipsis;
}
/* farooq */
.button-group-list {
  /* grid-column-gap: 1rem; */
  /* grid-row-gap: 1rem; */
  flex-flow: wrap;
  /* farooq */
  border-left: 1px solid #c1c1c1;
  /* width: 135px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  /* display: flex; */
  /*  */
}
/* farooq */
.price-summary {
  display: flex;
  padding: 20px 20px 0px 20px;
  justify-content: space-between;
}

.price-value {
}

/*  */
.button-group-details-list {
  /* grid-column-gap: 1rem; */
  /* grid-row-gap: 1rem; */
  flex-flow: wrap;
  /* farooq */
  border-left: 1px solid #c1c1c1;
  /* width: 135px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  /* display: flex; */
  /*  */
}
/*  */
.button-group.is-center {
  justify-content: center;
}

.hide {
  display: none;
}

.padding-section-medium {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.container-medium {
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
}

.form_radio {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-left: 0;
  display: flex;
}

.home-message_content-grid {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: start;
  display: grid;
}

.home-message_component {
  min-height: 100vh;
  color: #fff;
  padding: 3rem;
  display: flex;
}

.home-message_list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-items: start;
  display: grid;
}

.home-message_content {
  width: 100%;
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: stretch;
  display: flex;
}

.home-message_heading-block {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.home-message_item {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.home-message_item-icon {
  width: 1.75rem;
  height: 1.75rem;
  color: #000;
  background-color: #fff;
  flex: none;
}

.home-message_logo {
  width: 4rem;
}

.fs-styleguide_spacing-all {
  display: none;
}

.counters1_list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.counters1_component {
  padding-top: 0;
  padding-bottom: 0;
}

.border-2 {
  width: 1px;
  height: 20px;
  background-color: #000;
}

.counters1_item {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gallery_cs1_wrapper {
  display: flex;
}

.gallery_cs1_lightbox_img {
  height: 100%;
}

.scroll-up-arrow {
  z-index: 100;
  width: 60px;
  height: 60px;
  background-color: #e76666;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 10%;
  left: auto;
  right: 2%;
}

.scroll-up-arrow:hover {
  background-color: #fed800;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.scroll-up-arrow-link {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.fa-icon {
  font-family: "Font awesome 6 free solid 900", sans-serif;
  font-size: 1rem;
}

.fa-icon.arrow-up {
  font-size: 2rem;
}

.navbar1_component {
  width: 100%;
  height: auto;
  min-height: 4.5rem;
  /* background-color: rgba(0, 0, 0, 0); */
  align-items: center;
  padding: 0%;
  display: flex;
  position: fixed;
  top: 0;
}

.navbar1_component.set_2_sticky {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  align-items: stretch;
  padding: 0%;
}

.navbar1_component.set_3_absolute {
  position: absolute;
}

.navbar1_container {
  width: 100%;
  height: 100%;
  /* max-width: 80rem; */
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
/* farooq */
.navbar1_background_color {
  width: 100%;
  height: 100%;
  /* max-width: 80rem; */
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: #111;
}

/* farooq */

.navbar1_container.set_2 {
  z-index: 10;
  justify-content: center;
  padding-top: 1%;
  padding-bottom: 1%;
  position: relative;
}

.navbar1_logo-link {
  padding-left: 0;
}

.navbar1_logo {
  width: 94px;
  height: auto;
}

.navbar1_menu {
  align-items: center;
  display: flex;
  position: static;
}

.navbar1_link {
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

.navbar1_link:hover,
.navbar1_link.w--current {
  color: #f25f3a;
}

.navbar1_dropdown-toggle {
  color: #fff;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
}

.navbar1_dropdown-toggle:hover {
  color: #f25f3a;
}

.dropdown-icon {
  width: 1rem;
  height: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.navbar1_dropdown-list.w--open {
  background-color: #f25f3a;
  border: 1px #000;
  border-radius: 10px;
  padding: 0.5rem;
  overflow: hidden;
}

.navbar1_dropdown-link {
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

.navbar1_dropdown-link:hover,
.navbar1_dropdown-link.w--current {
  color: #000;
}

.navbar1_menu-buttons {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-left: 1rem;
  display: flex;
}

.navbar1_menu-button {
  padding: 0;
}

.section_header30 {
  position: relative;
}

.section_header30.position-static {
  position: static;
}

.header-100svh {
  max-height: 60rem;
  min-height: 100svh;
  align-items: center;
  display: flex;
}

.header-100svh.is-small {
  min-height: 50svh;
}

.header-100svh.is-flex-vertical {
  flex-direction: column;
}

.header30_background-image-wrapper {
  z-index: -1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.image-overlay-layer {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.header30_background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.section_layout240.background1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("../images/Rectangle-500.png");
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}

.layout240_component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.layout240_list {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.layout240_item {
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.layout240_image-wrapper {
  width: 100%;
  position: relative;
}

.layout240_image {
  width: 100%;
  height: 100%;
}

.icon-embed-xxsmall {
  width: 1rem;
  height: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section_layout144.background-1 {
  color: #fff;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.95) 21%,
      rgba(0, 0, 0, 0.65)
    ),
    url("../images/top-view-hands-holding-photos-1.png");
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}

.layout144_component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.layout144_lightbox {
  width: 100%;
  border: 1px solid #fff;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.layout144_lightbox-image {
  width: 100%;
  object-fit: cover;
}

.lightbox-play-icon {
  z-index: 2;
  width: 64px;
  height: 64px;
  color: #fff;
  position: absolute;
}

.video-overlay-layer {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.navbar1_link-dp {
  font-size: 0.8rem;
}

.sign-in-button-group {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  background-color: #fff;
  border-radius: 1000px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5% 1.5%;
  display: flex;
}

.language-block {
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  color: #000;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.globe-icon {
  width: 16px;
  height: 16px;
}

.second-nav-group {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.second-nav-group.show-mobile-portrait {
  display: none;
}

.menu2-icon {
  width: 16px;
  height: 16px;
  display: none;
}

.user-1-icon {
  width: 20px;
  height: 20px;
  display: none;
}

.user-menu {
  color: #f25f3a;
  align-items: center;
  padding-right: 0;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.user-menu:hover {
  color: #414042;
}

.nav-group-user-icon {
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  flex-direction: column;
  display: flex;
}

.splide {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  /* farooq changes */
  visibility: visible;
}

.splide__track {
  /* new */
  flex-direction: column;
  display: flex;
  /* new */
  overflow: hidden;
}

.splide__slide {
  width: 20%;
  height: 100%;
  flex-direction: column;
  flex: none;
  justify-content: center;
  padding: 1%;
  display: flex;
}

.splide__slide._3cols {
  width: 33.33%;
}

.splide__slide._4cols {
  width: 25%;
}

.header_slider-list-img {
  width: 100%;
  height: 100%;
}

.splide__list {
  /* new */
  flex-direction: row;
  /* new */
  display: flex;
}

.splide__arrows {
  z-index: 1;
  display: block;
}

.splide__arrow {
  width: 40px;
  height: 40px;
  color: #3b3b3b;
  background-color: #dee0e2;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 900;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: -2.5%;
}

.splide__arrow:hover {
  color: #fff;
  background-color: #f25f3a;
  border-color: #009cad;
}

.splide__arrow.splide__arrow--next {
  z-index: 2;
  width: 48px;
  height: 48px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  position: absolute;
  right: -3%;
}

.splide__arrow.splide__arrow--next:hover {
  color: #fff;
  transform: scale(1.1);
}

.splide__arrow.splide__arrow--prev {
  z-index: 2;
  width: 48px;
  height: 48px;
  border-style: none;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: -3%;
  right: auto;
}

.splide__arrow.splide__arrow--prev:hover {
  border-color: #00ab9f;
  transform: scale(1.1);
}

.h-slider-item {
  width: 100%;
  height: 100%;
  color: #fff;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
}

.h-slider-item:hover {
  transform: scale(1.05);
}

.text-color-orange1 {
  color: #f25f3a;
}

.text-style-camelcase {
  text-transform: capitalize;
}

.travel-search-form-1 {
  width: 80%;
  background-color: #efefef;
}

.travel-search-form-1.is-full {
  width: 100%;
}

.travel-form-block-wraper {
  margin-bottom: 0;
}

.travel-form-btn {
  width: 120px;
  height: 150px;
  background-color: #f25f3a;
  padding: 0;
}

.travel-form-btn:hover {
  background-color: #ff4c1f;
}

.travel-form-btn.set_wide.set_sticky {
  height: 80px;
}

.travel-form-btn.set_slim {
  height: 100px;
}

.travel-form-btn.set_slim.set_sticky {
  height: 80px;
}

.tf-wrapper {
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  display: flex;
}

.tf-wrapper.set2_wide {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  padding-left: 3%;
}

.tf-wrapper.set3_wide {
  max-width: 80rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  padding-left: 3%;
}

.tf-wrapper.set4_wide {
  /* farooq */
  /* max-width: 80rem; */
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  /* farooq */
  /* padding-left: 5%; */
}

.tf-wrapper.set5_wide {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  padding-left: 3%;
}

.tf-form-holder {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  display: flex;
}

.travel-field-label {
  font-size: 0.875rem;
  font-weight: 400;
}

.travel-field-label.text-weight-medium {
  font-weight: 500;
  white-space: nowrap;
}

.travel-field-input {
  border: 1px #000;
  margin-bottom: 0;
  padding: 0;
}

.travel-field-input::-ms-input-placeholder {
  color: #e0e0e0;
}

.travel-field-input::placeholder {
  color: #e0e0e0;
}

.travel-field-input.is-select-type {
  background-color: rgba(0, 0, 0, 0);
}

.travel-field-input.small {
  max-width: 6.3rem;
}

.header-con.is-v2 {
  height: 430px;
}

.travel-field-input-w-icon {
  height: 2.5625em;
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  align-items: center;
  padding: 0 6px;
  display: flex;
}

.video-float-intro-wrapper {
  width: 20%;
  margin-top: -50px;
}

.layout144_lightbox-2 {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.layout144_lightbox-image-2 {
  width: 100%;
  object-fit: cover;
}

.travel-form-component {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.f-t-holders {
  padding-right: 1rem;
}

.h-slider-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(19, 19, 19, 0.65);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  opacity: 0;
}

/* farooq */
.h-slider-overlay:hover {
  opacity: 1;
}
/*  */

.check-in-box {
  border: 1px solid #fff;
  margin-top: 10px;
  padding: 5px;
}

.sec_slide-item-img {
  width: 100%;
  height: 100%;
}

.sec_slide-item {
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
}

.sec_slide-item-overlay {
  width: 100%;
  height: 100%;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.75);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  position: absolute;
  opacity: 0;
}

/* farooq */
.sec_slide-item-overlay:hover {
  opacity: 1;
}
/*  */

.arrow-embeds {
  width: 30px;
  height: 30px;
}

.pop-gateways-items {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  flex-direction: column;
  text-decoration: none;
  display: flex;
}

.pop-gateways-items:hover {
  transform: scale(1.02);
}

.div-block-4 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pop-gateways-items-img {
  width: 100%;
  height: 100%;
}

.testimonial-wrapper {
  height: 100%;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 36px;
  padding: 2.25rem;
  display: flex;
}

.testimonial-img-wrapper {
  z-index: 2;
  margin-top: -71px;
  margin-bottom: 1rem;
  display: none;
  position: relative;
}

.testimonial-img {
  border: 5px solid #fff;
  border-radius: 50%;
}

.stars {
  color: #f4cd00;
  font-family: "Font awesome 6 free solid 900", sans-serif;
}

.section_blog34 {
  background-color: #f1f1f1;
}

.blog34_component {
  flex-direction: column;
  align-items: center;
}

.blog34_list {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

.blog34_item {
  background-color: #fff;
  transition: all 0.2s;
}

.blog34_item:hover {
  transform: scale(1.02);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.blog34_item.is-shadowed {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.blog34_image-link {
  width: 100%;
  margin-bottom: 1.5rem;
}

.blog34_image-wrapper {
  width: 100%;
  padding-top: 66%;
  position: relative;
  overflow: hidden;
}

.blog34_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.blog34_meta-wrapper {
  margin-bottom: 1rem;
}

.blog34_category-link {
  background-color: #f4f4f4;
  margin-right: 1rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;
}

.blog34_category-link.w--current {
  border-bottom: 2px solid #000;
}

.text-inline {
  display: inline;
}

.blog34_title-link {
  color: #1c2527;
  margin-bottom: 0.5rem;
  text-decoration: none;
  display: block;
}

.blog34_button-wrapper {
  margin-top: 1.5rem;
  display: flex;
}

.section_footer {
  background-color: #373737;
}

.footer_list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.75fr 0.75fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.footer_item {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.footer_links {
  height: 100%;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  display: flex;
}

.footer-link {
  color: #f1f1f1;
  text-decoration: none;
}

.footer-link:hover {
  color: #f25f3a;
}

.footer-link.is-flex {
  grid-column-gap: 10px;
  display: flex;
}

.image-3 {
  width: 75px;
  height: auto;
}

.div-block-9 {
  justify-content: flex-start;
  display: flex;
}

.image-4 {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.image-twt-logo {
  padding: 5px;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.socmed-item {
  width: 35px;
  display: inline-block;
}

.socmed-item:hover {
  transform: scale(1.05);
}

.socmed-ilists {
  grid-column-gap: 1rem;
  justify-content: flex-start;
  margin-top: 1rem;
  display: flex;
}

.section_footer-2 {
  background-color: #f25f3a;
}

.section_footer-3 {
  background-color: #373737;
}

.image-5 {
  width: 256px;
  height: 100%;
}

.div-block-12 {
  justify-content: center;
  padding-top: 1%;
  padding-bottom: 1%;
  display: flex;
}

.div-block-13 {
  justify-content: center;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
}

.xlayout1_list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.xlayout1_item {
  object-fit: cover;
}

.image-6 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section_xlayout2 {
  position: relative;
}

.xlayout2_list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.xlayout2_bg-offset {
  width: 80%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.xlayout2_bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.xlayout2_item-block {
  background-color: #fff;
  border-radius: 1em;
  padding: 30px;
  overflow: hidden;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.25);
}

.layout1_component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.layout1_image-wrapper.set_rounded-shadow {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.layout1_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.layout192_component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.layout192_image-wrapper.set_rounded-shadow {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}

.layout192_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.xlayout3_component {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.xlayout4_component {
  grid-column-gap: 0.5rem;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  display: grid;
}

.bf-header {
  color: #fff;
  background-color: #f25f3a;
  margin-bottom: 1rem;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.bf-field-group {
  margin-bottom: 2rem;
}

.bf-container {
  grid-row-gap: 0.5rem;
  border: 1px solid #e9e9e9;
  flex-direction: column;
  padding: 10px 20px;
  display: flex;
}

.bf-container.is-basic {
  display: block;
}

.bf-radio-button-field {
  flex-flow: row;
  align-items: flex-start;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.bf-radio-button-field.set_2 {
  grid-column-gap: 4px;
  align-items: center;
}

.bf-radio-button {
  width: 14px;
  height: 14px;
  border-color: #969696;
  border-radius: 0%;
  margin-top: 3px;
  margin-left: 0;
  margin-right: 10px;
}

.bf-radio-button:active {
  background-image: url("../images/check-mark.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.bf-radio-button.w--redirected-checked {
  background-image: url("../images/check-mark.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-style: none;
}

.bf-radio-button.w--redirected-focus {
  box-shadow: none;
  background-image: url("../images/check-mark.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.bf-radio-button.w--redirected-focus-visible {
  background-image: url("../images/check-mark.svg");
  background-position: 50%;
  background-size: cover;
}

.bf-radio-button.set_2_rounded {
  width: 18px;
  height: 12px;
  border-radius: 100%;
}

.bf-radio-button-label {
  width: 100%;
  margin-bottom: 0;
  margin-left: 0;
  font-size: 0.875rem;
}

.bf-result-item {
  grid-column-gap: 1rem;
  align-items: center;
  display: flex;
}
/* farooq */
.bf-result-item-list {
  grid-column-gap: 1rem;
  /* align-items: center; */
  display: flex;
}

.bf-result-item-detail-list {
  grid-column-gap: 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
/*  */
.bf-result-component {
  grid-row-gap: 2rem;
  background-color: #f9f9f9;
  flex-direction: column;
  padding: 30px;
  display: flex;
}
/* farooq */
.bf-result-component-list {
  grid-row-gap: 2rem;
  background-color: #f9f9f9;
  flex-direction: column;
  padding: 12px;
  display: flex;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  margin-bottom: 10px;
}
.rates {
  font-size: xx-large;
  font-weight: 600;
  color: #f25f3a;
}
.bf-result-component:hover {
  /* background-color: #e7f6f7;
  border-color: #abeff7;
  cursor: pointer; */
}
/*  */
.bf-result-component.is-single-page,
.bf-result-component.is-contact-form-page {
  grid-row-gap: 1rem;
}

.amenities-img {
  width: 20px;
  height: 20px;
}

/* farooq */

.amenities {
  column-count: 3;
  column-gap: 30px;
}

.amenity-group {
  break-inside: avoid;
}

.amenities ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 36px;
  margin-bottom: 36px;
  font-size: 14px;
  color: gray;
}
/*  */

.bf-imenities {
  grid-column-gap: 0.25rem;
  /* align-items: center; */
  display: flex;
}

.bf-item-aminities {
  grid-column-gap: 2rem;
  /* display: flex; */
}
.bf-item-booking-details {
  grid-column-gap: 2rem;
  display: flex;
}

.bf-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.singlebooking-bf-img-wrapper {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
}

/* farooq */
.room-names {
  width: 450px;
}

.bf-contents {
  width: 400px;
}
/* farooq */
.bf-contents-list {
  flex-basis: 50%;
}
.result-contents-list{
  flex-basis: 70%
}
.bf-contents-details-list {
  width: 110px;
}
/*  */
.bf-border {
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
}

.padding-section-tiny {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.angle-right-icon {
  color: #bababa;
  font-family: "Font awesome 6 free solid 900", sans-serif;
  font-size: 0.75rem;
}

.breadcrumb-group {
  grid-column-gap: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.breadcrumb {
  color: #bababa;
  text-decoration: none;
}

.breadcrumb.is-active {
  color: #414042;
}

.bf-gallery-component {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-rows: auto;
  /* grid-template-columns: 2fr 1fr; */
  grid-auto-columns: 1fr;
  display: grid;
}

.bf-lb-main-img.h-full {
  height: 100%;
}

.bf-lb-main-tb-img {
  width: 140px;
  height: 100%;
  object-fit: cover;
}

.bf-lb-main-tb-component {
  width: 100%;
  height: 100%;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  display: grid;
}

.bf-lb-main-tb-img-in {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bf-lb-main-img-in.h-full {
  height: 100%;
}

.bf-field-wrapper {
  grid-column-gap: 1rem;
  display: flex;
}

.bf-field-wrapper.set_2_vertical {
  align-items: center;
}

.bf-field-wrapper.j_sb {
  justify-content: space-between;
  align-items: center;
}

.bf-page-info {
  padding-bottom: 5px;
}

.bf-page-info.is-text-info {
  grid-row-gap: 0.5rem;
  flex-direction: column;
  display: flex;
}

.bf-page-img-wrapper {
  margin-bottom: 0.5rem;
  display: block;
}

.bf-page-img-con {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.form-holder-item1 {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 20px;
}

.blog34-contents {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.content30_content-top {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  display: flex;
}

.content30_breadcrumb {
  width: 100%;
  align-items: center;
  display: flex;
}

.breadcrumb-link {
  color: #000;
  text-decoration: none;
}

.breadcrumb-link.w--current {
  font-weight: 600;
}

.breadcrumb-divider {
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
}

.content30_share {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-items: start;
  margin-top: 1rem;
  display: grid;
}

.content30_share.content-top {
  margin-top: 0;
}

.content30_social-link {
  color: #f25f3a;
  background-color: #f4f4f4;
  border-radius: 20px;
  padding: 0.25rem;
}

.content30_social-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.content30_content {
  margin-bottom: 4rem;
}

.content30_content-bottom {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.content30_share-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.content30_tag-list-wrapper {
  width: 100%;
}

.content30_tag-list {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.content30_tag-item {
  color: #f25f3a;
  background-color: #f4f4f4;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;
}

.content30_tag-item.w--current {
  border-bottom: 2px solid #000;
}

.content30_divider {
  width: 100%;
  height: 1px;
  max-width: 48rem;
  background-color: #000;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.content30_author-wrapper {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.content30_author-image-wrapper {
  margin-bottom: 1rem;
}

.content30_author-image {
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  object-fit: cover;
  border-radius: 100%;
}

.tf-component {
  z-index: 100;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
}

.tf-component.is-sticky {
  z-index: -1;
  width: 100%;
  max-width: none;
  padding-left: 0%;
  padding-right: 0%;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.tf-component.is-sticky-header,
.tf-component.is-sticky-header-2 {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.div-block-15 {
  position: relative;
  top: 52px;
}

.h-tform {
  padding-bottom: 20%;
  position: relative;
}

.section-splidesliders {
  margin-top: -17%;
}

.nav-main {
  width: 100%;
  max-width: 80rem;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}

.sitemap-link {
  color: #111;
  text-decoration: none;
}

.sitemap-link:hover {
  color: #f25f3a;
}

.sitemap-blocks {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.testimonial19_component {
  height: auto;
  background-color: rgba(0, 0, 0, 0);
  padding-bottom: 4rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  overflow: hidden;
}

.testimonial19_mask {
  width: 33.33%;
  overflow: visible;
}

.testimonial19_slide {
  padding-left: 1rem;
  padding-right: 1rem;
}

.testimonial19_content {
  width: 100%;
  border: 1px solid #d3d3d3;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  display: flex;
}

.testimonial19_rating-wrapper {
  margin-bottom: 1.5rem;
  display: flex;
}

.testimonial19_rating-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #f25f3a;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
  display: flex;
}

.testimonial19_client {
  text-align: left;
  align-items: center;
  margin-top: auto;
  display: flex;
}

.testimonial19_client-image-wrapper {
  margin-right: 1rem;
}

.testimonial19_customer-image {
  width: 3rem;
  height: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  object-fit: cover;
  border-radius: 100%;
}

.testimonial19_arrow {
  width: 3.5rem;
  height: 3.5rem;
  background-color: #f25f3a;
  border: 1px #000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 4rem;
}

.testimonial19_arrow-icon {
  width: 1rem;
  height: 1rem;
  color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.testimonial19_slide-nav {
  height: 1.75rem;
  font-size: 0.5rem;
}

.contact11_component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.contact11_form_component {
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
}

.contact11_form {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.success-message {
  background-color: #f4f4f4;
  padding: 1.5rem;
}

.success-text {
  font-weight: 600;
}

.error-message {
  margin-top: 1.5rem;
  padding: 0.875rem 1rem;
}

.error-text {
  color: #e23939;
}

.contact11_contact-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 2.5rem;
  grid-template-rows: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.icon-embed-small {
  width: 2rem;
  height: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact-link {
  color: #f25f3a;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

.border-3 {
  width: 1px;
  height: 10px;
  background-color: #666;
  padding: 0;
}

.div-block-16 {
  width: 100%;
  grid-column-gap: 1rem;
  display: flex;
}

.div-block-17 {
  color: #414141;
  text-decoration: none;
}

.div-block-18 {
  width: 400px;
  padding-right: 25%;
}

.section_xlayout5 {
  background-color: #efefef;
  margin-top: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.section_xlayout6 {
  background-color: #414042;
  margin-bottom: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

.hotel-form-block {
  margin-bottom: 0;
}

.hotel-form {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.hotel-selected-wrapper {
  width: 250px;
  height: 100px;
  border: 1px solid #505050;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.hotel-selected-block-text {
  justify-content: center;
  align-items: center;
  display: flex;
}

.hotel-selected {
  grid-column-gap: 0.5rem;
  align-items: center;
  display: flex;
}

.hotel-selected-img-wrapper {
  position: relative;
}

.hotel-selected-img {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.hotel-selected-name {
  color: #fff;
}

.close-button {
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #f25f3a;
  border-radius: 1000px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-family: "Font awesome 6 free solid 900", sans-serif;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: -15%;
  bottom: auto;
  left: -15%;
  right: auto;
}

.close-button:hover {
  background-color: #bababa;
}

.xlayout6-padding-section-tiny {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bf-showmore {
  color: #f25f3a;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.875rem;
  text-decoration: none;
}

.bf-showmore:hover {
  color: #ff4c1f;
}

.cover-in-page {
  background-color: #fff;
  border-bottom: 10px solid #ff4c1f;
  border-radius: 30px;
  padding: 5%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.topdestination_item-1 {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: grid;
}

.topdestination_content {
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.topdestination_item-img-con {
  border-radius: 30px;
  display: block;
  overflow: hidden;
}

.topdestination_item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topdestination-component {
  grid-row-gap: 2rem;
  flex-direction: column;
  display: flex;
}

.title-contaiiner {
  margin-top: -17%;
}

.topdestination_item-2 {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: grid;
}

.top-destination-left-arrow {
  height: 370px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  margin-top: 0;
  margin-bottom: 0;
  transition: all 0.2s;
  display: block;
}

.top-destination-left-arrow:hover {
  background-image: linear-gradient(to right, #f25f3a, rgba(0, 0, 0, 0));
}

.top-destination-right-arrow {
  height: 370px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-top: 0;
  margin-bottom: 0;
  transition: all 0.2s;
  display: block;
}

.top-destination-right-arrow:hover {
  background-image: linear-gradient(270deg, #f25f3a, rgba(0, 0, 0, 0));
}

.image-7 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider {
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0);
}

.slide {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
}

.div-block-19 {
  height: 370px;
  object-fit: cover;
}

.mask {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.slide-nav {
  position: relative;
  top: auto;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.top-destination-slider-view {
  height: auto;
}

.section_toptosee {
  background-color: #353535;
  background-image: url("../images/Top-Things.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-destination-contents {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  color: #fff;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 4rem;
  display: grid;
}

.top-destination-texts {
  color: #fff;
  font-weight: 600;
}

.sub-destination-item {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 2rem;
  display: grid;
}

.sub-destination-list {
  grid-row-gap: 0.5rem;
  flex-direction: column;
  display: flex;
}

.text-align-justified {
  text-align: justify;
}

.div-block-20 {
  justify-content: center;
  display: flex;
}

.cookie-consent-wrapper {
  z-index: 9999;
  display: block;
  position: relative;
}

.fs-cc-banner2_component {
  z-index: 999;
  width: 28.125rem;
  color: #fff;
  background-color: #ff4c1f;
  padding: 2rem;
  display: none;
  position: fixed;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.fs-cc-banner2_container {
  width: 100%;
  max-width: 60rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.fs-cc-banner2_text {
  font-size: 1rem;
}

.fs-cc-banner2_text-link {
  color: #fff;
  border-bottom: 2px solid #fff;
  font-weight: 400;
  text-decoration: none;
}

.fs-cc-banner2_buttons-wrapper {
  flex: none;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.fs-cc-banner2_button {
  min-width: 7.5rem;
  color: #f25f3a;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  flex: 1;
  margin-left: 0;
  margin-right: 1rem;
  padding: 0.5rem 1.25rem;
  font-weight: 700;
}

.fs-cc-banner2_button.fs-cc-button-alt {
  color: rgba(255, 255, 255, 0.6);
  background-color: #f25f3a;
}

.fs-cc-manager2_component {
  z-index: 999;
  display: none;
  position: fixed;
  top: auto;
  bottom: 1.25rem;
  left: 1.25rem;
  right: auto;
}

.fs-cc-manager2_button {
  width: 3rem;
  color: #f25f3a;
  cursor: pointer;
  background-color: #fff;
  flex-direction: column;
  padding: 0.5rem;
  transition: color 0.2s;
  display: flex;
}

.fs-cc-manager2_button:hover {
  color: #4353ff;
}

.fs-cc-prefs2_component {
  z-index: 997;
  opacity: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 2rem;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.fs-cc-prefs2_form-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 1.5rem;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.fs-cc-prefs2_form {
  width: 100%;
  max-height: 85vh;
  max-width: 25rem;
  color: #fff;
  background-color: #333;
  margin-bottom: 0;
  position: relative;
}

.fs-cc-prefs2_close {
  color: #fff;
  cursor: pointer;
  background-color: #f25f3a;
  padding: 0.75rem;
  font-size: 1.25rem;
  text-decoration: none;
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
  box-shadow: -1px 1px 4px rgba(51, 51, 51, 0.1);
}

.fs-cc-prefs2_close:hover {
  background-color: #ff4c1f;
}

.fs-cc-preferences2_close-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
}

.fs-cc-prefs2_content {
  height: 100%;
  color: #000;
  background-color: #fff;
  padding: 2.5rem 2rem;
}

.fs-cc-prefs2_space-small {
  margin-bottom: 0.75rem;
}

.fs-cc-prefs2_title {
  color: #010440;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2em;
}

.fs-cc-prefs2_option {
  border-bottom: 1px solid rgba(192, 192, 192, 0.3);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.fs-cc-prefs2_toggle-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.fs-cc-prefs2_label {
  color: #ff4c1f;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2rem;
  font-size: 1rem;
  font-weight: 700;
}

.fs-cc-prefs2_checkbox-field {
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0.125rem;
  display: flex;
  position: relative;
}

.fs-cc-prefs2_checkbox {
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #fff;
  border-width: 2px;
  border-color: #f25f3a;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.fs-cc-prefs2_checkbox.w--redirected-checked {
  background-color: #f25f3a;
  background-size: auto;
  border-color: #f25f3a;
}

.fs-cc-prefs2_checkbox-label {
  display: none;
}

.fs-cc-prefs2_buttons-wrapper {
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  margin-top: 1rem;
  display: flex;
}

.fs-cc-prefs2_button {
  text-align: center;
  background-color: #f25f3a;
  border: 1px solid #f25f3a;
  padding: 0.75rem 1.25rem;
  font-weight: 700;
}

.fs-cc-prefs2_button:hover {
  background-color: #ff4c1f;
}

.fs-cc-prefs2_button.fs-cc-button-alt {
  color: #f25f3a;
  background-color: #fff;
  border-color: #f25f3a;
  margin-bottom: 0.75rem;
}

.fs-cc-prefs2_submit {
  text-align: center;
  background-color: #380b00;
  margin-top: 0.75rem;
  padding: 0.75rem 1.25rem;
  font-weight: 700;
}

.fs-cc-prefs2_overlay {
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 2, 0, 0.7);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.icon-seach-wrapper {
  width: 42px;
  /* farooq */
  /* background-color: #f25f3a; */
  /*  */
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.dropdown-rooms-guest.w--open {
  background-color: #efefef;
  padding: 28px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.div-block-22 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.button-plus-minus {
  background-color: #f25f3a;
  padding: 2px 12px;
  font-size: 1.25rem;
  font-weight: 500;
}

.button-plus-minus:hover {
  background-color: #ff4c1f;
}

@media screen and (min-width: 1280px) {
  .xlayout2_bg-offset {
    width: 80%;
  }

  .xlayout4_component {
    grid-template-columns: 0.25fr 1fr;
  }
}

@media screen and (min-width: 1440px) {
  .navbar1_container.set_2 {
    max-width: none;
  }

  .tf-wrapper.set4_wide {
    /* farooq */
    /* max-width: none;
    padding-left: 15%; */
  }

  .tf-wrapper.set4_wide.set_2_singlebooking,
  .tf-wrapper.set4_wide.set_2_groupbooking {
    padding-left: 5%;
  }

  .tf-wrapper.set5_wide {
    max-width: none;
    padding-left: 10%;
  }

  .travel-form-component.set_2_singlebooking-wrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .sec_slide-item-img,
  .pop-gateways-items-img {
    height: 100%;
  }

  .xlayout2_item-block {
    padding: 60px;
  }

  .bf-lb-main-tb-img {
    width: 150px;
  }

  .title-contaiiner {
    margin-top: -13%;
  }
}

@media screen and (min-width: 1920px) {
  .navbar1_container.set_2 {
    max-width: none;
  }

  .travel-search-form-1 {
    width: 75%;
  }

  .tf-wrapper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: flex-end;
  }

  .tf-wrapper.set2_wide {
    padding-left: 0%;
  }

  .tf-wrapper.set3_wide {
    justify-content: center;
    padding-left: 0%;
  }

  .tf-wrapper.set4_wide {
    max-width: none;
    justify-content: space-between;
  }

  .tf-wrapper.set4_wide.set_2_singlebooking,
  .tf-wrapper.set4_wide.set_2_groupbooking {
    justify-content: center;
    padding-left: 5%;
  }

  .tf-wrapper.set5_wide {
    max-width: none;
    justify-content: space-between;
  }

  .video-float-intro-wrapper {
    width: 25%;
  }

  .travel-form-component.set_2_singlebooking-wrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .xlayout2_bg-offset {
    width: 60%;
  }

  .bf-lb-main-tb-img {
    width: 160px;
  }

  .title-contaiiner {
    margin-top: -10%;
  }
}

@media screen and (max-width: 991px) {
  .padding-xlarge {
    padding: 3rem;
  }

  .margin-xlarge {
    margin: 3rem;
  }

  .padding-xhuge {
    padding: 6rem;
  }

  .padding-xxhuge {
    padding: 8rem;
  }

  .padding-huge {
    padding: 5rem;
  }

  .margin-large {
    margin: 2.5rem;
  }

  .padding-xxlarge {
    padding: 4rem;
  }

  .padding-large {
    padding: 2.5rem;
  }

  .margin-huge {
    margin: 5rem;
  }

  .padding-medium {
    padding: 1.5rem;
  }

  .margin-xxlarge {
    margin: 4rem;
  }

  .margin-xhuge {
    margin: 6rem;
  }

  .margin-medium {
    margin: 1.5rem;
  }

  .margin-xxhuge {
    margin: 8rem;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .hide-tablet {
    display: none;
  }

  .fs-styleguide_2-col {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
  }

  .max-width-full-tablet {
    width: 100%;
    max-width: none;
  }

  .text-weight-normal.text-size-xlarge {
    font-size: 2.5rem;
  }

  .fs-styleguide_1-col {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  .fs-styleguide_heading-medium {
    font-size: 3rem;
  }

  .fs-styleguide_4-col {
    grid-template-columns: 1fr;
  }

  .padding-section-large {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .fs-styleguide_3-col {
    grid-template-columns: 1fr;
  }

  .button.is-nav-top-right {
    white-space: pre;
    display: flex;
  }

  .fs-styleguide_section {
    grid-column-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .padding-section-medium {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .navbar1_menu {
    -webkit-text-fill-color: inherit;
    background-color: #fff;
    background-clip: border-box;
    border-bottom: 1px solid #000;
    padding: 1rem 5% 2rem;
    position: absolute;
    overflow: auto;
  }

  .navbar1_menu.is-page-height-tablet {
    z-index: 9999;
    height: 100dvh;
    padding-bottom: 6.5rem;
    transition: height 0.5s;
  }

  .navbar1_link {
    width: auto;
    color: #f25f3a;
    padding: 0.75rem 0;
    font-size: 1.125rem;
  }

  .navbar1_link:hover {
    color: #000;
  }

  .navbar1_menu-dropdown {
    width: 100%;
    font-size: 1.125rem;
  }

  .navbar1_dropdown-toggle {
    color: #f25f3a;
    align-items: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0;
    display: flex;
  }

  .navbar1_dropdown-toggle:hover {
    color: #000;
  }

  .dropdown-icon {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    top: auto;
    bottom: auto;
    left: auto;
    right: 0%;
  }

  .navbar1_dropdown-list {
    position: static;
    overflow: hidden;
  }

  .navbar1_dropdown-list.w--open {
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    padding: 0;
  }

  .navbar1_dropdown-link {
    width: auto;
    color: #f25f3a;
    padding: 0.75rem 0 0.75rem 5%;
    font-size: 1rem;
  }

  .navbar1_dropdown-link:hover {
    color: #000;
  }

  .navbar1_menu-buttons {
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    align-items: stretch;
    margin-top: 1.5rem;
    margin-left: 0;
  }

  .navbar1_menu-button.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .menu-icon1 {
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -0.5rem;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .menu-icon1_line-top {
    width: 24px;
    height: 2px;
    background-color: #fff;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon1_line-middle {
    width: 24px;
    height: 2px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .menu-icon_line-middle-inner {
    width: 4px;
    height: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon1_line-bottom {
    width: 24px;
    height: 2px;
    background-color: #fff;
    padding-bottom: 0;
    padding-right: 0;
  }

  .header30_background-image-wrapper.set-2 {
    height: 900px;
  }

  .layout240_list {
    grid-column-gap: 2rem;
  }

  .layout144_lightbox-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .navbar1_link-dp {
    font-size: 1.125rem;
  }

  .sign-in-button-group {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding: 2% 4%;
  }

  .language-block {
    width: 43px;
  }

  .second-nav-group {
    width: 260px;
  }

  .user-menu {
    padding-right: 0;
  }

  .nav-group-user-icon {
    flex-direction: row;
    display: flex;
  }

  .splide__slide._3cols {
    width: 50%;
  }

  .splide__slide._4cols {
    width: 33%;
  }

  .h-slider-item {
    border-radius: 20px;
  }

  .travel-search-form-1 {
    width: 100%;
  }

  .travel-form-btn.set_wide {
    width: 100%;
    height: auto;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .travel-form-btn.set_wide.set_sticky {
    height: auto;
  }

  .travel-form-btn.set_slim {
    height: auto;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: 8px;
  }

  .travel-form-btn.set_slim.set_sticky {
    height: auto;
  }

  .tf-wrapper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
  }

  .tf-wrapper.set2_wide,
  .tf-wrapper.set3_wide,
  .tf-wrapper.set4_wide {
    align-items: stretch;
    padding: 3%;
  }

  /* farooq */
  .tf-wrapper.set3_wide {
    display: none;
  }

  .travel-field-label {
    font-size: 0.7rem;
  }
  /*  */

  .travel-field-label {
    white-space: nowrap;
    font-size: 0.7rem;
  }

  .video-float-intro-wrapper {
    width: 50%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .layout144_lightbox-image-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .travel-form-component {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
  }

  .blog34_list {
    grid-row-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .footer_list {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .xlayout1_list {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: row;
    grid-template-rows: auto;
    grid-template-columns: 1fr 0.25fr 1fr;
    grid-auto-columns: 1fr;
    align-items: stretch;
    display: grid;
  }

  .layout1_component {
    min-height: auto;
    grid-column-gap: 3rem;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    display: flex;
  }

  .layout192_component {
    min-height: auto;
    grid-column-gap: 3rem;
    flex-direction: column-reverse;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    display: flex;
  }

  .xlayout4_component {
    grid-template-columns: 0.75fr 1fr;
  }
  .xlayout4_component_hotel_response {
    grid-row-gap: 2rem;
    flex-direction: column;
    display: flex;
  }
  .bf-result-item {
    grid-row-gap: 2rem;
    flex-direction: column;
    align-items: stretch;
  }

  .bf-result-component {
    padding: 20px;
  }

  .bf-item-aminities.is-single-page {
    grid-row-gap: 0.75rem;
    flex-direction: column;
  }
  .bf-item-booking-details.is-single-page {
    grid-row-gap: 0.75rem;
    flex-direction: column;
  }


  .bf-img-wrapper {
    width: 100%;
  }

  .bf-contents {
    width: auto;
  }

  .breadcrumb-group {
    grid-row-gap: 1rem;
    flex-wrap: wrap;
  }

  .bf-gallery-component {
    flex-direction: column;
    grid-template-columns: 1fr;
    display: flex;
  }

  .bf-lb-main-tb-component {
    grid-column-gap: 2px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .bf-field-wrapper {
    flex-direction: column;
  }

  .content30_content {
    margin-bottom: 3rem;
  }

  .tf-component.is-sticky {
    display: none;
  }

  .section-splidesliders {
    margin-top: -19%;
  }

  .testimonial19_mask {
    width: 50%;
  }

  .testimonial19_slide {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .testimonial19_arrow {
    width: 3rem;
    height: 3rem;
  }

  .contact11_component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .div-block-18 {
    width: 100%;
    padding-right: 0%;
  }

  .section_xlayout6 {
    top: 170px;
  }

  .hotel-form {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .hotel-selected-wrapper {
    width: 100%;
    height: 60px;
  }

  .hotel-selected {
    width: 100%;
  }

  .hotel-selected-img {
    width: 50px;
    height: 50px;
  }

  .topdestination_item-1 {
    flex-direction: column;
    align-items: stretch;
    display: flex;
  }

  .topdestination_item-2 {
    flex-direction: column-reverse;
    align-items: stretch;
    display: flex;
  }

  .sub-destination-item {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1rem;
  }

  .padding-xlarge {
    padding: 2rem;
  }

  .margin-xlarge {
    margin: 2rem;
  }

  .padding-xhuge {
    padding: 4rem;
  }

  .padding-xxhuge {
    padding: 4.5rem;
  }

  .padding-huge {
    padding: 3.5rem;
  }

  .margin-large {
    margin: 1.5rem;
  }

  .padding-xxlarge {
    padding: 3rem;
  }

  .padding-large {
    padding: 1.5rem;
  }

  .margin-huge {
    margin: 3.5rem;
  }

  .padding-medium {
    padding: 1.25rem;
  }

  .margin-xxlarge {
    margin: 3rem;
  }

  .margin-xhuge {
    margin: 4rem;
  }

  .margin-medium {
    margin: 1.25rem;
  }

  .margin-xxhuge {
    margin: 4.5rem;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .hide-mobile-landscape {
    display: none;
  }

  .fs-styleguide_heading-large {
    font-size: 3rem;
  }

  .heading-style-h3 {
    font-size: 1.5rem;
  }

  .heading-style-h3-new {
    font-size: 1.5rem;
  }

  .heading-style-h1 {
    font-size: 2.5rem;
  }

  .padding-global {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }

  .text-weight-normal.text-size-xlarge {
    font-size: 1.75rem;
  }

  .padding-section-small {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .fs-styleguide_section-header {
    font-size: 0.875rem;
  }

  .fs-styleguide_heading-medium {
    font-size: 2rem;
  }

  .max-width-full-mobile-landscape {
    width: 100%;
    max-width: none;
  }

  .heading-style-h4 {
    font-size: 1rem;
  }

  .text-style-nowrap {
    white-space: normal;
  }

  .heading-style-h6 {
    font-size: 0.75rem;
  }

  .padding-section-large {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .text-size-large {
    font-size: 1.25rem;
  }

  .heading-style-h2 {
    font-size: 2rem;
  }

  .fs-styleguide_version,
  .heading-style-h5 {
    font-size: 0.875rem;
  }

  .padding-section-medium {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .home-message_component {
    padding: 0;
  }

  .home-message_content {
    border-radius: 0;
  }

  .navbar1_component {
    height: auto;
    min-height: 4rem;
  }

  .navbar1_logo-link {
    padding-left: 0;
  }

  .navbar1_menu.is-page-height-tablet {
    padding-bottom: 6rem;
  }

  .header30_background-image-wrapper.set-2 {
    height: auto;
  }

  .layout240_list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .splide__slide._3cols {
    width: 50%;
  }

  .splide__arrow {
    right: -5%;
  }

  .h-slider-item {
    border-radius: 15px;
  }

  .travel-field-wrapper.set_3 {
    width: 200px;
  }

  .travel-form-btn {
    width: 100%;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .tf-wrapper {
    flex-direction: column;
    align-items: stretch;
    padding-top: 5%;
    padding-right: 5%;
  }

  .tf-wrapper.set5_wide {
    padding-left: 0%;
    padding-right: 0%;
  }

  .tf-form-holder {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .tf-form-holder.set_2_flex {
    width: 800px;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    overflow: auto;
  }

  .header-con {
    padding-top: 2rem;
  }

  .video-float-intro-wrapper {
    width: 95%;
  }

  /* farooq */
  .price-summary {
    display: flex;
    padding: 20px 20px 0px 20px;
  }

  .price-value {
    right: 300px;
  }

  /*  */
  .t-btn-holder {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .t-btn-holder.set_2 {
    padding-left: 5%;
    padding-right: 5%;
  }

  .f-t-holders {
    padding-right: 0;
  }

  .f-t-holders.set_2 {
    width: 100%;
    padding-bottom: 15px;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: auto;
  }

  .check-in-box {
    display: none;
  }

  .blog34_list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .xlayout1_list {
    align-items: stretch;
  }

  .layout1_component,
  .layout192_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .xlayout4_component {
    grid-row-gap: 2rem;
    flex-direction: column;
    display: flex;
  }

  .padding-section-tiny {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .bf-lb-main-tb-img {
    width: 100%;
  }

  .bf-lb-main-tb-component {
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-items: center;
    display: grid;
  }
  /* farooq */
  .bf-lb-main-tb-component-rates {
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    grid-template-rows: auto;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-auto-columns: 1fr;
    justify-items: center;
    display: grid;
    margin-left: 40px;
  }
  /*  */

  /* farooq  */

  .image-gallery {
    width: 100%;
    height: auto;
  }

  .image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
  }

  .fullscreen .image-gallery-slide img {
    max-height: 100vh;
  }
  /*  */
  .content30_content-top,
  .content30_content {
    margin-bottom: 2rem;
  }

  .content30_divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .tf-component.is-sticky {
    display: none;
  }

  .tf-component.is-sticky-header-2 {
    padding-left: 0%;
    padding-right: 0%;
  }

  .h-tform {
    padding-bottom: 10%;
  }

  .section-splidesliders {
    margin-top: -2%;
  }

  .testimonial19_component {
    padding-bottom: 3rem;
    padding-left: 0;
    padding-right: 0;
  }

  .testimonial19_mask {
    width: 100%;
  }

  .testimonial19_slide {
    margin-right: 2rem;
    padding-left: 0;
    padding-right: 0;
  }

  .testimonial19_content {
    padding: 1.5rem;
  }

  .testimonial19_client {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .testimonial19_client-image-wrapper {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .testimonial19_arrow {
    bottom: 3rem;
  }

  .testimonial19_arrow.hide-mobile-landscape {
    display: none;
  }

  .contact11_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .section_xlayout5 {
    overflow: hidden;
  }

  .section_xlayout6 {
    top: 190px;
    overflow: auto;
  }

  .hotel-form {
    display: flex;
  }

  .hotel-selected-block-text {
    width: 175px;
  }

  .hotel-selected {
    width: 175px;
    justify-content: center;
  }

  .hotel-button-block {
    padding-right: 40px;
  }

  .xlayout6-padding-section-tiny {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .cover-in-page {
    border-bottom-width: 6px;
  }

  .top-destination-left-arrow,
  .top-destination-right-arrow,
  .div-block-19 {
    height: 250px;
  }

  .top-destination-contents {
    flex-direction: column;
    margin-top: 2rem;
    display: flex;
  }

  .fs-cc-banner2_container {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 479px) {
  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .max-width-full-mobile-portrait {
    width: 100%;
    max-width: none;
  }

  .hide-mobile-portrait {
    display: none;
  }

  .heading-style-h6.text-color-black.set-2 {
    font-size: 1rem;
  }

  .heading-style-h2.text-color-white.set-2 {
    font-size: 1.5rem;
  }

  .button {
    width: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .button.is-third.max-width-full-tablet {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.85rem;
  }

  .counters1_list {
    flex-wrap: wrap;
  }

  .border-2 {
    display: none;
  }

  .scroll-up-arrow {
    width: 50px;
    height: 50px;
  }

  .fa-icon.arrow-up {
    font-size: 1.5rem;
  }

  .section_header30.position-static {
    padding-top: 65px;
  }

  .header30_background-image-wrapper.set-2 {
    height: auto;
  }

  .layout240_list {
    grid-template-columns: 1fr;
  }

  .layout144_component.set-2 {
    align-items: stretch;
  }

  .sign-in-button-group {
    width: 100%;
    text-align: center;
    background-color: #f25f3a;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 10px;
  }
  .button-background-white{
    background-color: white;
  }
  .sign-in-button-group:hover {
    background-color: #ff4c1f;
  }

  .second-nav-group {
    width: 100%;
    justify-content: flex-start;
  }

  .second-nav-group.show-mobile-portrait {
    display: flex;
  }

  .user-menu,
  .user-menu:hover {
    color: #fff;
  }

  .splide__slide._3cols,
  .splide__slide._4cols {
    width: 100%;
  }

  .splide__arrow.splide__arrow--next,
  .splide__arrow.splide__arrow--prev {
    display: none;
  }

  .travel-field-wrapper.set_3 {
    width: 250px;
  }

  .tf-wrapper.set2_wide,
  .tf-wrapper.set3_wide,
  .tf-wrapper.set4_wide {
    padding: 5%;
  }

  .tf-wrapper.set5_wide {
    padding: 5% 0%;
  }

  .tf-form-holder {
    grid-template-columns: 1fr;
  }

  .tf-form-holder.set_2_flex {
    width: 1000px;
  }

  .t-btn-holder {
    margin-bottom: 1rem;
  }

  .f-t-holders {
    padding-right: 0;
  }

  .testimonial-wrapper {
    padding: 1rem;
  }

  .footer_list {
    text-align: center;
    flex-direction: column;
    display: flex;
  }

  .footer-link.is-flex {
    grid-column-gap: 5px;
    justify-content: center;
  }

  .div-block-9 {
    justify-content: center;
  }

  .socmed-ilists {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: center;
  }

  .xlayout1_list {
    flex-direction: column;
    display: flex;
  }

  .xlayout2_list {
    grid-template-columns: 1fr;
  }

  .xlayout2_bg-offset {
    width: 100%;
  }

  .layout1_component,
  .layout192_component {
    grid-template-columns: 1fr;
  }

  .breadcrumb-group {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
  }

  .bf-lb-main-tb-component {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  .content30_content-top {
    flex-direction: column;
    align-items: flex-start;
  }

  .content30_share.content-top {
    margin-top: 2rem;
  }

  .content30_content-bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .content30_share-wrapper {
    width: 100%;
    margin-bottom: 2rem;
  }

  .content30_tag-list-wrapper {
    width: 100%;
  }

  .content30_tag-list {
    justify-content: flex-start;
  }

  .section-splidesliders {
    margin-top: -6%;
  }

  .tf-header {
    display: none;
  }

  .contact11_component,
  .contact11_contact-list {
    grid-template-columns: 1fr;
  }

  .div-block-16 {
    flex-direction: column;
  }

  .section_xlayout6 {
    padding-left: 10px;
    overflow: auto;
  }

  .hotel-form {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-flow: row;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    padding-right: 60px;
    display: flex;
  }

  .hotel-selected-block-text {
    width: 150px;
  }

  .hotel-selected {
    width: 150px;
    justify-content: center;
  }

  .hotel-button-block {
    padding-right: 50px;
  }

  .xlayout6-padding-section-tiny {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .cover-in-page {
    border-radius: 20px;
    padding: 10% 8%;
  }

  .top-destination-left-arrow,
  .top-destination-right-arrow {
    height: 200px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .div-block-19 {
    height: 200px;
  }

  .fs-cc-banner2_component {
    width: 100%;
  }

  .fs-cc-prefs2_component {
    padding: 1.5rem;
  }

  .fs-cc-prefs2_close {
    padding: 0.5rem;
  }

  .fs-cc-prefs2_content {
    padding: 1.5rem;
  }

  .fs-cc-prefs2_title {
    font-size: 1.125rem;
  }

  .fs-cc-prefs2_label {
    font-size: 0.875rem;
  }

  .fs-cc-prefs2_button.fs-cc-button-alt {
    margin-bottom: 0.5rem;
  }

  .fs-cc-prefs2_submit {
    margin-top: 8px;
  }
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627ca17-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ca1c-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ca21-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ca26-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ca2c-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ca32-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ca38-e9c419cb {
  justify-self: start;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cb45-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cb5e-e9c419cb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cb61-e9c419cb {
  justify-self: start;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cb63-e9c419cb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cb66-e9c419cb {
  justify-self: start;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cbb8-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cbc2-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cbc3-e9c419cb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc31-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc36-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc3b-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc40-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc45-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc4a-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc54-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc59-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc5e-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc63-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc68-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc6d-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc72-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc77-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc7c-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc81-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc86-e9c419cb {
  justify-self: start;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc8a-e9c419cb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc8b-e9c419cb {
  justify-self: start;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc8d-e9c419cb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc90-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc95-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cc9a-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccaf-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccb4-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccb9-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccbe-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccc3-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccc8-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccd2-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccd7-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccdc-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cce1-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cce6-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cceb-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccf0-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccf5-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccfa-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627ccff-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cd04-e9c419cb {
  justify-self: start;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cd08-e9c419cb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cd09-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cd0e-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cd13-e9c419cb,
#w-node-_32eadc24-e391-a089-abe0-fb5b6627cd18-e9c419cb {
  justify-self: start;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cd6b-e9c419cb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_32eadc24-e391-a089-abe0-fb5b6627cdc7-e9c419cb {
  justify-self: start;
}

#w-node-_4f3415b6-e3fd-1cc3-bab5-4f756a08af85-a0c84e2a,
#w-node-ea8e33bf-e79b-8132-7a3d-4675962e3e69-a0c84e2a {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_7d73387f-b51a-5a27-a420-c053469e5f6e-14878508 {
  justify-self: start;
}

@media screen and (max-width: 991px) {
  #w-node-_3e34f168-55bc-e256-d19e-2b9b29033f4a-29033f43,
  #w-node-_978fdbd6-9006-6784-15c0-76c7080ad7d5-47368b72 {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  #w-node-ab920361-cb35-e8ca-07d7-e61b29b07545-47368b72 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-d92a83f0-6de8-e908-049f-341497420964-47368b72 {
    order: -9999;
  }
}

@font-face {
  font-family: "Font awesome 6 free 400";
  src: url("../fonts/Font-Awesome-6-Free-Regular-400.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Font awesome 6 brands 400";
  src: url("../fonts/Font-Awesome-6-Brands-Regular-400.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Font awesome 6 free solid 900";
  src: url("../fonts/Font-Awesome-6-Free-Solid-900.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*Changes*/
@media screen and (max-width: 991px) {
  .bf-contents-list {
    width: 100%;
  }
  .bf-result-item-list {
    display: flex;
    flex-direction: column;
  }
  .bf-item-aminities {
    display: flex;
  }
  .bf-item-popular-amenities {
    display: flex;
    flex-direction: column;
  }
  /* .bf-result-component-list {
    grid-row-gap: 2rem;
    background-color: #f9f9f9;
    flex-direction: column;
    padding: 12px;
    display: flex;
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    margin-bottom: 10px;
  } */
  .singlebooking-bf-img-wrapper {
    flex-basis: 177px;
    display: flex;
    flex-direction: column;
  }
  .singlebooking-button-group-list {
    border-left: 0px;
    margin-top: 30px;
  }
  .resultpage-button-group-list{
    margin-top: 15px;
    margin-bottom: 15px;
    border-left: 0px;
  }
  .button-group-details-list {
    padding-left: 0px;
    border-left: 0px;
  }
  .bf-result-item-detail-list {
    flex-direction: column;
  }
  .align-text-center {
    text-align: center;
  }
  .room-names {
    width: auto;
  }
}
@media screen and (max-height: 567px) {
  .header30_background-image-landscape {
    height: 150%;
  }
}
.bf-item-popular-amenities {
  grid-column-gap: 2rem;
}

/*style start*/
/*main sec booking detial start*/

.booking-leftSide {
  background: #F0F0F0;
  padding: 0px 40px;
}
.left_sec img {
  width: 120px;
  height: 70px;
}
.right-section {
  background: #f0f0f0;
  padding: 20px 20px;
}
a.buttonCancel.w-button {
  background: no-repeat;
  color: #000;
  text-decoration: underline;
}
.xlayout4_component.xlayout8_4_component{
  grid-template-columns: 1fr .50fr;
}

.image_sec {
  display: flex;
  column-gap: 20px;
}


@media screen and (max-width: 991px) {
  .xlayout4_component.xlayout8_4_component {
    grid-template-columns: 6fr;
  }
  span.right_bottom.text-size-tiny.address_bookinDetail {
    line-height: 15px;
    text-align: end;
  }
  .flex-column-image{
    flex-direction: column !important;
  }
}

/*main sec booking detial end*/
.section_padding{
  padding: 40px;
}
.cancellatinBtn {
  background-color: transparent !important;
  color: #000 !important;
  font-size: 12px;
}
.my_bookingPage .margin-medium {
  margin: 1rem;
}
.my_bookingPage .bf-img-wrapper {
  width: 600px;
  height: 250px;
}
.my_bookingPage .bf-result-item {
  align-items: flex-start;
}
.my_bookingPage .button-group {
  grid-row-gap: inherit;
}
.my_bookingPage .xlayout4_component {
  display: inherit;
}
.my_bookingPage .bf-contents {
  width: 850px;
}
.my_accountInfo {
  border-radius: 10px;
}
.cancellatinBtn {
  background-color: transparent !important;
  color: #000;
  font-size: 12px;
}
.Change_pwdMyaccount.my_accountInfo {
  color: #fff;
  background-color: #B8B8B8;
  margin-bottom: 1rem;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.my_bookingPage .bf-imenities{
  display: block;
}
.my-accountleftside {
  background: #F0F0F0;
  padding: 20px 20px;
}
#wf-form-Booking-Form-Details .form_input {
  background-color: rgb(255 255 255) !important;
}
#wf-form-Booking-Form-Details-Password .form_input {
  background-color: rgb(255 255 255) !important;
}
/*Booking Detail Page*/
.left_bookingDetial {
  line-height: 30px;
  font-size: 12px;
  color: #646464;
}
.booking-listLeft, .bookingRightSide {
  /* background-color: #F0F0F0; */
  padding: 10px;
  display: flex;
  column-gap: 20px;
}
.booking-listLeft .left_sec img {
  width: 150px;
  height: auto;
}
.booking-listLeft .heading-style-h6, .bookingRightSide .heading-style-h6{
  font-size: 1.5rem;
}
.booking-listLeft .right_sec {
  margin-left: 10px;
}
.booking-listLeft .text-size-small {
  font-size: 1.2rem;
}
.bf-result-component-update-form{
  margin-bottom: 20px;
  background-color: #F0F0F0;
}
.button-group-update{
  display: flex;
}
.hotel-search-img-size{
  height: 150px;
  min-width: 250px
}
.rating-groople{
  display: flex;
  align-items: center;
}

.credit-card-number-input{
  flex: 0.75;
}
.credit-card-expiry-input{
  flex: 1.35;
  gap: 10px;
}
.credit-card-cvc-input{
  flex: 0.25;
}
@media screen and (max-width: 400px) {
  .credit-card-expiry-input{
    flex: 1.35;
    gap: 10px;
    flex-direction: column;
  }
}
.margin-top-small{
  margin-top: 1rem;
}
.flex-box {
  display: flex;
  gap: 20px;
  align-items: center;
}
.orange-label {
  background: #F15D3F;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
  border-radius: 4px;
}
.d-flex{
  display: flex;
}
.d-flex-wrap{
  flex-wrap: wrap;
}
.d-grid{
  display: grid;
}

.grid-temp-3{
  grid-template-columns: 25% 60% 15%;
  gap: 10px;
}

.gap-3{
  grid-column-gap: 1rem
}
.blue-label {
  background: #57DBDA;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
  border-radius: 4px;
}
.mr-3{
  margin-right: 1rem;
}
.w-30{
  width: 30%;
}
.w-40{
  width: 40%;
}
.w-50{
  width: 50%;
}
.w-100{
  width: 100%;
}
.mt-1{
  margin-top: 10px;
}

.tab-sec{
  border-top: 4px solid #FF5638;
}



.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  color: #ff5638;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ff5638;
  color: #fff;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
  overflow-x: scroll;
}

#Room-rate{
  display: block;
}

.custom-table th, .custom-table td{
  text-align: left;
  padding: 10px;
}

.custom-table th {
  background: #e0e0e0;
}

.custom-table td{
  border-bottom: 1px solid #ddd;
}

.total-price table td {
  padding: 10px 20px;
}

.direct-column{
  flex-direction: column;
}
.d-gap-3{
  gap: 20px;
}

.hr-w-100{
  width: 100%;
}
.hotel-name-fix-text{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.custom-datepicker{
  right: -7rem;
}

.overflowX-scroll{
  overflow-x: scroll;
}
@media screen and (max-width: 991px) {
  .grid-temp-3{
    grid-template-columns: 20% 60% 20%;
    grid: none;
  }
  .form-row-gap{
    gap: 1rem;
  }
}
@media screen and (max-width: 456px) {
  .tab button{
    width: 100%;
  }
  .justify-content-space-between{
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) and (max-width:792px) {
  .tab button{
    width: 100%;
  }
}
.hotel-amenities{
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}
.padding-section-title {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.heading-style-h3-new{
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.2;
}

.clearfix {
  clear: both;
  }
  
  #contact-cta-section {
  display: flex;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  text-align: center;
  background-color: black; 
  }
  
  .wrapper-full {
    position: relative;
  width: 1280px;
  margin: 0 auto;
  }

  @media (max-width: 840px) {
    .cta-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2em;
    }
    .cta-wrapper .blue-cta-button {
      width: unset !important;
    }
  }

  .cta-wrapper {
  margin-bottom: 0;
  text-align: left;
  padding: 28px 28px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  }
  .cta-wrapper .details-wrapper {
  float: left;
  width: 60%;
  }
  .cta-wrapper .details-wrapper h2 {
  font-weight: 600;
  margin: 0 0 4px 0;
  color: #FFF;
  font-size: 27px;
  }
  .cta-wrapper .details-wrapper p {
  font-size: 18px;
  color: #FFF;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  
  .cta-wrapper .blue-cta-button {
    padding: 17px 50px;
    text-transform: uppercase;
    background-color: #93CC76;
    color: #FFF;
    letter-spacing: 0.1em;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
    white-space: pre;
    margin: 31px 0 25px 0;
    float: right;
    margin: 0;
    color: #fff;
    background-color: #f25f3a;
    padding: 17px 0;
    width: 18%;
    text-align: center;
  }
  
  .cta-wrapper .blue-cta-button:hover {
    color: #333;
    background-color: #c44520;
}


@media print {
  .print-hide, .print-hide *{
    display: none;
  }
  .printableContent,
  .printableContent * {
    visibility: visible;
  }
  .print-header-text, .print-header-text *{
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .print-margin-top-0{
    margin-top: 0;
  }
  .print-margin-bottom-0{
    margin-bottom: 0;
  }
  @page{
    size: A3;
  }
}
